<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Tipos de Produtos</h3>
        </div>
        <div class="card-body">
          <div class="form-group row justify-content-center align-items-md-center">
            <div class="col-md-12">
              <input required type="text" class="form-control" v-model="filtro" placeholder="Buscar..." />
            </div>
          </div>
          <b-table :fields="['nome', 'acoes']" :items="lista_tipos_produto" :per-page="perPage"
            :current-page="currentPage" id="produto-table"
            class="table table-head-custom table-vertical-center table-head-bg table-borderless" show-empty
            empty-text="Nenhum registro encontrado!">

            <template #head(acoes)><span></span></template>
            <template #cell(acoes)="{ item }">
              <div class="text-right w-100">

                <button v-show="lista_permissoes_filial.u_Sala" @click="atualizar(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Editar registro">
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button v-show="lista_permissoes_filial.lock_Sala" @click="confirm(item)"
                  class="btn btn-icon btn-light btn-sm mx-1" v-b-tooltip.hover title="Bloquear/Desbloquear registro">
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
              </div>
            </template>
          </b-table>
          <b-pagination v-model="currentPage" :total-rows="lista_tipos_produto.length" :per-page="perPage"
            aria-controls="produto-table">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins: { dateFormat },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      filtro: ""
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Tipos" }]);
  },
  created() {
    this.listar_tipos_produto();
  },
  watch: {
    filtro() {
      if (this.filtro.length >= 2) this.pesquisar_tipo();
      if (this.filtro.length == 0) this.listar_tipos_produto();
    },
  },
  computed: {
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async listar_tipos_produto() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/listar_tipos_produto").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    async pesquisar_tipo() {
      await this.$store.dispatch("produto/pesquisar_tipos_produto", this.filtro)
    },
    atualizar(value) {
      this.$router.push({ name: "createTipoProduto" });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara esta Sala no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("configEmpresa/bloquear_sala", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>